
:root {
  --background-colour: white;
  --text-colour: #7c9bad;
  --menu-colour: #88a7bc;
  --menu-text-colour: white;
  --menu-active: #e7e7e7;
  --signal: #dd2913;
}

body {
  background-color: var(--background-colour);
  color: var(--text-colour);
}

.contacts {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .contacts {
      line-height: 30px;
  }
}

@media (max-width: 338px) {
  .contacts {
      font-size: 24px;
  }
}

.home-list {
  float: none;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.question {
  font-weight: bold;
}

.answer {
  padding-left: 25px;
}

.container img {
  padding: 1px;
  display: inline;
  background: #cccccc;
  border: 4px solid var(--menu-colour);
}

h1 {
  padding-top: 43px;
  font-size: 54px;
  line-height: 45px;
  padding-bottom: 35px;
  font-family: Times New Roman;
  color: var(--menu-text-colour);
}

@media (max-width: 767px) {
  h1 {
      padding-bottom: 0;
  }
}

@media (max-width: 338px) {
  h1 {
      font-size: 36px;
  }
}

h2 {
  text-align: center;
}

h3 {
  text-align: left;
}

.listpadded {
  padding-bottom: 20px;
}

.text-muted a {
  color: var(--menu-text-colour);
}

body > div#root > .container {
  padding: 28px 15px 0;
}

.gallerythumbnails > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .gallerythumbnails > div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .gallerythumbnails > div  {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallerythumbnails > div > div {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  display: grid;
  justify-items: center;
}

.grid-wrapper {
  justify-content: center;
}

.navbutton {
  cursor: pointer;
}

/* Error Handling */
.error {
  color: var(--signal);
}

input.error, input[type=email]:invalid, input[type=password]:invalid, input[type=text]:invalid, select:invalid, textarea.error, textarea:invalid {
  box-shadow: 0 0 0 2px var(--signal);
}

/* Bootstrap tweaks */
.page-header {
  text-align: center;
  border: none;
  background-image: url(./assets/template/blue-tiles.png);
  background-repeat: repeat-x;
  background-position: center top;
  height: 176px;
  margin: 0;
  margin-top: 51px;
}

.navbar-toggle {
  float: left;
  margin-left: 15px;
}

.navbar-default {
  background-color: var(--menu-colour);
}

  .navbar-default .navbar-nav > li > .dropdown-menu {
      background-color: var(--menu-colour);
  }

  .navbar-default .navbar-nav > li > a, .dropdown-menu > li > a {
      color: var(--menu-text-colour);
      font-weight: bold;
  }



.container .text-muted {
  color: var(--menu-text-colour);
  margin: 10px 0;
}

#footer {
  background-color: var(--menu-colour);
  height: 40px;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: var(--menu-active);
}
